#registration_short,
#profile_preferences {
  .date-of-birth {
    .form-item:nth-of-type(3n + 1) {
      width: 33.33333%;
      float: left;
      margin-right: -100%;
      padding-right: 1em;
      clear: both;
    }
    .form-item:nth-of-type(3n + 2) {
      width: 33.33333%;
      float: left;
      margin-right: -100%;
      margin-left: 33.33333%;
      clear: none;
      padding-right: 1em;
    }
    .form-item:nth-of-type(3n + 3) {
      width: 33.33333%;
      float: right;
      margin-right: 0;
      margin-left: 0;
      clear: none;
      padding-right: 0;
    }
  }
  .ship_type_container {
    span.required_mark {
      display: none;
    }
    .form-item.ship_type {
      padding-top: 1.5em;
      span.radio {
        margin-bottom: 0;
      }
    }
  }
  .title_container {
    .picker-radio {
      display: inline-block;
      margin: 0 1em 0 0;
      .picker-handle {
        margin-right: 10px;
      }
    }
    label.label {
      display: block;
      padding: 5px 0;
    }
  }
  .birthday-fields {
    display: block;
    padding-bottom: 5px;
  }
  .gender_label {
    display: block;
    padding-bottom: 5px;
  }
  .picker-radio {
    margin-top: 0;
  }
}

.favorites-page {
  .favorites-back {
    display: none;
  }
}

.site-container {
  .product__footer {
    a.notify_me {
      margin: 0;
      height: 30px;
    }
    .product-item__unavailable-message {
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding: 0;
      top: 48px;
      line-height: inherit;
    }
    .product-item__sold-out {
      float: right;
      height: 40px;
      line-height: 40px;
      margin: 0;
    }
  }
}

.fade-in {
  background: url('/media/export/images/global/ajax-loader-skybox.gif') no-repeat center;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  padding-top: 18px;
}

form.adpl label {
  display: inline-block;
}
