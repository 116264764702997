@mixin underline_a {
  border-bottom: none;
  position: relative;
}

@mixin underline_after {
  content: '';
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 2px;
  border-width: 0 0 1px;
  border-style: solid;
}

.back-to-mac {
  .back-to-mac__step {
    display: none;
  }
  .back-to-mac__intro {
    margin-bottom: 0px;
  }
}

.site-navigation {
  &__dropdown {
    .site-navigation {
      &__submenu {
        .site-navigation {
          &__submenu-col {
            min-width: 135px !important;
          }
        }
      }
    }
  }
}

.product.prod_inv_status-3 {
  .product__footer {
    .product__inventory-status {
      .coming-soon {
        display: block;
      }
    }
  }
}

.customer-service__navigation {
  .customer-service__menu {
    #contact_us_nav {
      a {
        cursor: pointer;
      }
    }
  }
}

#livechat_link {
  a {
    cursor: pointer;
  }
}

.culture-landing__column {
  .culture-landing__item:nth-child(2n + 3) {
    clear: left;
  }
  .culture-landing__item:nth-child(2n + 4) {
    clear: right;
  }
}

a.text-block__cta {
  @include underline_a;
}

a.text-block__cta:after {
  @include underline_after;
  bottom: 5px !important;
}

.page-search__form {
  #search {
    font-size: 30px !important;
  }
}

.view-all-shades--desktop {
  padding-top: 27px;
}

.site-navigation__carousel {
  .carousel-controls {
    a {
      font-size: 15px !important;
    }
  }
}

.site-banner {
  height: 60px;
}

.media-block__body {
  margin: 0.3em 0;
}

body.section-privacy-policy {
  .site-container {
    .narrowed_body_copy {
      max-width: 900px;
      h2 {
        font-size: 48px;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        a.notify_me {
          height: 30px;
          line-height: 30px;
          font-size: 15px;
          width: auto;
          clear: none;
          float: right;
          padding: 0 15px;
          top: inherit;
        }
      }
    }
  }
}

.page--spp__product {
  .product__footer {
    .product__shipping {
      clear: both;
    }
  }
}

.store_landing_only {
  .tout-block-landscape {
    max-width: 1280px;
  }
}

#colorbox {
  iframe.waitlist-iframe-wrapper {
    width: 95%;
    height: 205px;
  }
}

.tiny-waitlist-overlay {
  #waitlist {
    .field {
      .email_input {
        width: 80%;
        float: left;
      }
      .align-r {
        float: left;
        input.input-btn {
          height: 64px;
          margin-left: 7px;
        }
      }
    }
    .waitlist_message {
      margin-bottom: 5px;
    }
    .waitlist_header {
      font-size: 3rem;
      margin-top: 0px !important;
    }
  }
  .waitlist_thankyou_heading {
    font-size: 3rem;
  }
}

.grid--mpp {
  .product--teaser.product--shaded {
    .product__link-to-spp {
      padding: 0 15px;
    }
  }
}

.tout-block-landscape {
  img {
    @include swap_direction(margin, 0 0 0);
  }
}

@include breakpoint($bp--xlarge-down) {
  .container-table {
    width: 100%;
    @include swap_direction(margin, 0 0 0 auto);
  }
  .cta-table {
    width: 16vw;
    height: 5vw;
    font-size: 15px;
    line-height: 53px;
    display: inline-block;
    @include swap_direction(margin, 0 0 0 3vw);
  }
}

@include breakpoint($bp--xxlarge-up) {
  .container-table {
    width: 1144px;
    @include swap_direction(margin, 0 0 16px auto);
  }
  .cta-table {
    width: 198px;
    height: 66px;
    font-size: 15px;
    line-height: 53px;
    display: inline-block;
    @include swap_direction(margin, 0 0 0 45px);
  }
}

#node {
  &-340960 {
    background: url('/media/export/cms/collections/love_me_spp/love_me_spp_purplebkgd_pc.jpg')
      no-repeat center;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
  }
  &-341386 {
    background: url('/media/export/cms/collections/love_me_spp/love_me_spp_redbkgd_pc.jpg')
      no-repeat center;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
  }
  &-341340 {
    background: url('/media/export/cms/collections/love_me_spp/love_me_spp_services_bg_pc.jpg')
      no-repeat center;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
  }
  &-341402 {
    .product-brief-carousel {
      .slick-slider {
        background-color: rgb(255, 255, 255);
      }
    }
  }
}

.site-header {
  .site-navigation {
    .checkout-navigation {
      &__menu {
        display: none;
      }
    }
  }
}

#search-wrapper {
  .product {
    &__price {
      &--sale {
        display: block;
        margin-top: -20px;
        margin-bottom: -20px;
      }
    }
  }
}

.tabbed-products-block-v2 {
  .has-alt-image {
    cursor: pointer;
    .product-brief__image-img {
      display: block;
      &--alt-image {
        display: none;
      }
    }
    &:hover {
      .product-brief__image-img {
        display: none;
        &--alt-image {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.mpp-custom-override-layout {
  .product--teaser {
    background: $color-cta-grey--light;
    padding: 0 10px;
    .product-brief {
      &__container {
        border: unset;
      }
    }
  }
}
