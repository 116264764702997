#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          #pr-rd-filter-variant-by {
            font-size: 17px;
          }
          .pr-rd-search-container {
            .pr-rd-search-reviews-input {
              input {
                padding: 0 0 0 36px;
                height: 62px !important;
                color: $color--black;
              }
              .pr-rd-search-reviews-icon-button {
                width: 33px;
              }
            }
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              font-size: 17px;
              &:before {
                content: 'ใช่ (';
                font-size: 17px;
              }
              &:after {
                font-size: 17px;
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              font-size: 17px;
              &:before {
                content: 'ไม่ (';
                font-size: 17px;
              }
              &:after {
                font-size: 17px;
              }
            }
          }
          .pr-rd-flag-review-container {
            margin-right: 12px;
            line-height: 1.5;
            a {
              font-size: 17px;
            }
          }
          .pr-rd-helpful-text {
            font-size: 17px;
          }
        }
        .pr-rd-header {
          .pr-rd-review-headline {
            font-size: 22px;
            margin-top: -1%;
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            span {
              font-size: 17px;
            }
          }
        }
        .pr-rd-description {
          .pr-rd-right {
            p,
            span {
              font-size: 17px;
            }
          }
        }
        .pr-modal-content {
          .pr-control-label {
            font-size: 17px;
          }
          .pr-flag-review-btn {
            font-size: 17px;
          }
          .pr-flagging-radio {
            span {
              font-size: 17px;
            }
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            padding-left: 42%;
            font-size: 17px;
          }
          span,
          p {
            font-size: 17px;
          }
        }
      }
      .pr-rd-description {
        .pr-rd-description-text {
          font-size: 17px;
        }
      }
      .pr-rd-def-list {
        dt,
        dd {
          font-size: 17px;
        }
      }
      .pr-rd-no-reviews {
        .pr-snippet-write-review-link {
          font-size: 17px;
        }
      }
    }
    .pr-review-snapshot-block-container {
      .pr-review-snapshot-block {
        &.pr-review-snapshot-block-pros {
          .pr-review-snapshot-tags {
            .pr-review-snapshot-block-headline {
              font-size: 20px;
            }
          }
        }
        &.pr-review-snapshot-block-cons {
          .pr-review-snapshot-snippets {
            .pr-snippet-stars-reco-stars {
              .pr-snippet-read-and-write {
                .pr-snippet-review-count {
                  font-size: 16px !important;
                }
              }
            }
          }
        }
        .pr-msq-list {
          .pr-filter-btn {
            .pr-filter-btn__child {
              &.pr-filter-btn__buttonText,
              &.pr-filter-btn__voteCount {
                font-size: 23px;
              }
            }
          }
          .pr-more-button {
            span {
              font-size: 20px;
            }
          }
        }
        .pr-review-snapshot-histogram {
          .pr-histogram-label,
          .pr-histogram-count {
            font-size: 19px;
          }
        }
        .pr-review-snapshot-snippets {
          .pr-snippet-stars-reco-inline {
            .pr-snippet-stars-reco-stars {
              .pr-snippet {
                .pr-snippet-read-and-write {
                  .pr-snippet-write-review-link {
                    font-size: 20px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet {
        .pr-snippet-read-and-write {
          a {
            font-size: 17px !important;
          }
        }
      }
    }
  }
}

.product__footer {
  .pr-snippet-stars-reco-inline {
    .pr-snippet-stars-reco-stars {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          font-size: 17px !important;
          &:before {
            content: 'อ่านบทวิจารณ์ทั้งหมด  ';
            font-size: 17px;
          }
        }
      }
      .pr-snippet-stars-container {
        &:before {
          content: 'คะแนนโดยรวม: ';
          font-size: 17px;
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    span {
      font-size: 17px;
    }
    .pr-war {
      #pr-war-form {
        .form-group {
          textarea::placeholder,
          input::placeholder {
            font-size: 17px;
          }
          .btn-toggle {
            .pr-btn {
              font-size: 17px;
            }
          }
          select {
            font-size: 17px;
          }
          .pr-btn-add-tag {
            a {
              font-size: 17px;
            }
          }
        }
      }
      .pr-submit {
        .pr-footer {
          .pr-subscript {
            font-size: 17px;
            a {
              font-size: 17px;
            }
          }
        }
      }
    }
    .thank-you-page {
      .review-preview {
        .pr-text {
          font-size: 17px;
        }
      }
    }
  }
}
