.picker-flag:before {
  vertical-align: inherit !important;
}

.order-details-page {
  .order_table {
    float: right;
    width: auto;
  }
  .order-summary-panel__title {
    text-align: right;
    padding-right: 87px;
  }
}

#checkout-sidebar {
  .order-summary__content {
    .order_table {
      width: 100%;
    }
  }
}

.single-blocks-home {
  padding: 0 6px;
  .tout-block-landscape {
    padding: 0;
  }
}
