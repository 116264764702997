.checkout {
  &.viewcart {
    .checkout-header {
      &__title {
        width: 100%;
        padding: 1em 0;
      }
      &__samples {
        text-align: center;
        margin-bottom: 20px;
      }
      &__subhead {
        border: 0;
      }
      #top-viewcart-buttons {
        width: 100%;
        .continue-buttons {
          width: 100%;
          padding-right: 0;
          text-align: center;
        }
      }
      .continue-shopping {
        display: none;
      }
    }
    .cart-item {
      .remove_link {
        float: left;
        line-height: 14px;
        padding-top: 2px;
        margin-right: 5px;
      }
    }
    .cart-item__product-price {
      &--non-sale {
        text-decoration: line-through;
        display: block;
      }
      &--sale {
        color: red;
      }
    }
    .panel.need-help-panel a:visited {
      color: white;
    }
    .recommended-products-panel {
      .recommended-product-items {
        .recommended-item {
          font-size: 12px !important;
          .addtobag {
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .continue-buttons {
          padding-right: 20%;
          text-align: right;
        }
      }
    }
  }
  &.samples-panel {
    .checkout-header {
      &__title {
        width: 100%;
        padding: 1em 0;
      }
    }
    #checkout_samples {
      .product__description {
        height: 105px;
      }
    }
  }
  &.shipping {
    #shipping-address-info-panel {
      .address-container.shipping-address {
        float: left;
        width: 47%;
        min-height: 50px;
        margin: 0 2% 0 0;
      }
      .address_controls {
        #choose-address {
          .address-select {
            width: 50% !important;
          }
        }
      }
    }
    #create_account_form_container {
      .email_container {
        .label-class {
          display: block;
        }
      }
    }
    .shipping-billing-address-section {
      #shipping-address-info-panel {
        fieldset.address {
          .picker.picker-radio {
            float: left;
          }
          .title_container {
            label {
              display: block;
            }
          }
        }
        .address-container {
          .select-store-map {
            font-weight: bold;
          }
        }
      }
      #delivery-instructions {
        .required_mark {
          float: left;
        }
        label {
          display: block;
        }
        textarea {
          max-width: 660px;
        }
      }
      .address-container {
        p {
          margin-bottom: 0;
          padding-top: 5px;
        }
        .form_element {
          .overlay-addclass-checkout-additional-info-overlay {
            margin-left: 10px;
            position: relative;
            bottom: 5px;
          }
          .select2-container.field,
          select.field {
            width: 50% !important;
          }
        }
      }
    }
    #giftwrap-info-panel {
      #message {
        label {
          display: block;
        }
      }
    }
  }
  &.review {
    #review-address-info {
      .trans_detail_item {
        float: left;
        margin-bottom: 25px;
        margin-right: 25px;
        position: relative;
        width: 47%;
        word-wrap: break-word;
      }
      .trans_header {
        .change_link {
          float: right;
          margin-right: 15%;
          margin-top: 7px;
        }
      }
    }
    #top-viewcart-buttons {
      display: none;
    }
    #bottom-viewcart-buttons {
      .continue-buttons {
        text-align: left;
        .checkout-buttons {
          width: 228px;
        }
      }
    }
    .checkout-header__subhead {
      display: none;
    }
    .checkout__content {
      .viewcart-panel {
        .cart-item__thumb {
          width: 10.66667%;
        }
      }
    }
  }
  &.confirmation {
    #confirmation-panel {
      .confirmation-panel__title {
        padding-left: 0;
      }
      .confirmation-panel__content {
        padding: 2em 0;
      }
      .checkout__panel-title {
        margin-top: 0px;
        padding-top: 1em;
      }
    }
    #return-user {
      .password {
        label.label {
          display: inline-block;
        }
        .password_field {
          input {
            width: 50%;
          }
        }
      }
    }
  }
  h2.signin {
    text-align: center;
    padding: 0.5em 0;
    border-bottom: 1px solid #dbdbdb;
  }
  .checkout__sidebar {
    #need-help-panel.need-help-panel.panel {
      a {
        color: $color--white;
      }
    }
  }
  .checkout__content {
    .order-summary__content {
      #checkout_shipmethod {
        .form-item.ship-method {
          min-width: 265px;
          width: auto;
        }
      }
    }
  }
  #offer-code-panel {
    #offer_code {
      margin-top: 10px;
    }
    .offer-code__input {
      input[type='text'] + label:before {
        background: none;
        overflow: hidden;
        max-width: 100%;
        margin-left: 10px;
        font-size: 15px;
        background-color: $color--gray--white;
      }
    }
  }
  .samples-page {
    .samples-panel__heading {
      font-size: 23px;
      text-transform: none;
    }
  }
  .bundle-kit {
    .product__desc-info {
      min-height: 50px;
    }
    .kit-container {
      .product__desc-info {
        min-height: auto;
      }
    }
  }
}

#sign-in-page {
  input[type='submit'] {
    width: 100%;
  }
}

#shipping-page-info-panel {
  #checkout_shipping {
    .address_form_container {
      .title_container {
        .picker.picker-radio {
          display: inline-block;
          .picker-handle {
            margin-right: 5px;
          }
        }
      }
    }
  }
  #payment-container {
    .picker.picker-radio {
      .picker-handle {
        margin-right: 5px;
      }
    }
  }
  .delivery-info-content,
  #delivery-options {
    .shipform_fields {
      label {
        display: inline-block;
      }
    }
  }
  .address_form_container {
    .title_container {
      margin-bottom: 0;
    }
  }
  #bottom-viewcart-buttons {
    .continue-buttons {
      text-align: left;
    }
  }
  #top-viewcart-buttons {
    display: none;
  }
  #delivery-info-panel {
    #delivery-instructions {
      h4 label {
        display: inline;
      }
    }
  }
  fieldset.fs {
    .picker.picker-radio {
      float: left;
    }
  }
}

#payment-container {
  .payment_label label {
    display: block;
  }
  .picker.picker-radio {
    .picker-label {
      line-height: 22px;
    }
  }
  .bank-image-message {
    background: url(/media/images/checkout/bank_logos.gif) no-repeat -30px top;
    width: 100px;
    height: 25px;
    float: left;
    position: relative;
    top: -1px;
  }
}

.shipping_wrapper {
  .fade-in {
    background: url('/media/images/global/ajax-loader.gif') no-repeat center center #000000;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.5;
  }
}

.adpl {
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='text'] {
    &.error {
      border-color: $color--red;
    }
  }
}
