.appt-book {
  .appointment-nav {
    .submenu__content {
      .submenu__nav {
        .appointment-nav__link {
          font-size: 20px;
        }
      }
    }
  }
  .appt-book-page-header-content {
    .appt-book-page-header-content__dots {
      li {
        font-size: 25px;
        line-height: 25px;
        .appt-book-page-header-content__separator {
          height: 25px;
        }
      }
    }
    .appt-book-page-header-content__subhead {
      p {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
  .appt-book-location-selector {
    font-size: 20px;
    .form-container {
      .location-select {
        font-size: 18px;
        option {
          font-size: 18px;
        }
      }
    }
  }
  .service-select {
    .book-now-bar,
    .book-now-bar.bottom {
      .start-over {
        font-size: 20px;
        line-height: 25px;
      }
      .button {
        font-size: 25px;
        .book-now-bar__details {
          .price,
          .separator,
          .minutes {
            font-size: 25px;
          }
        }
      }
    }
    .services-container {
      .service {
        height: 360px;
        .service-details {
          .service-type {
            font-size: 18px;
            line-height: 20px;
          }
          .service-title {
            line-height: 38px;
            font-size: 35px;
          }
          .service-selection-content {
            font-size: 18px;
            line-height: 20px;
          }
          .service-description {
            p {
              font-size: 18px;
              line-height: 20px;
            }
          }
        }
        .selection-bar {
          .selection-data {
            font-size: 26px;
            position: absolute;
            bottom: 0px;
          }
          .selection-selector {
            font-size: 20px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .appointment-select {
    .booking-step.step1 {
      .services {
        .service-type {
          font-size: 20px;
          line-height: 20px;
        }
        .service-head {
          font-size: 35px;
          line-height: 35px;
        }
        .selection-data {
          font-size: 30px;
          line-height: 40px;
        }
        .change-services {
          a#change-services-link {
            font-size: 18px;
          }
          &:after,
          &:before {
            left: -14px;
          }
        }
      }
    }
    .booking-step.step2 {
      .artist-select-container {
        .artist-select.selectbox {
          font-size: 18px;
          option {
            font-size: 18px;
          }
        }
      }
      .selects-container {
        .select-container {
          h4 {
            font-size: 20px;
            line-height: 20px;
          }
          .selectbox {
            font-size: 18px;
            option {
              font-size: 18px;
            }
          }
        }
      }
      .appointment-select {
        h4 {
          font-size: 20px;
          line-height: 20px;
        }
        .calendar-head {
          .overlay-content {
            .pika-lendar {
              .pika-title {
                .pika-label {
                  font-size: 16px;
                }
              }
              .pika-table {
                th {
                  font-size: 16px;
                }
                td {
                  .pika-button {
                    font-size: 16px;
                  }
                }
              }
            }
          }
          .cal-copy {
            font-size: 20px;
          }
          .cal-head-controls {
            .cal-controls {
              font-size: 18px;
            }
          }
        }
        .calendar_wrapper {
          .col-heads {
            th {
              font-size: 16px;
            }
          }
          .timeslots {
            td {
              font-size: 16px;
            }
          }
        }
      }
      .book-now-bar {
        .button {
          font-size: 25px;
        }
        .start-over {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }
  .confirm {
    .confirm-container {
      .sign-in-container {
        .sign-in-link-container {
          a,
          h4 {
            font-size: 18px;
          }
        }
      }
      .book-appt-container {
        .book-appt-header {
          span {
            font-size: 18px;
          }
        }
        .registration__privacy_policy-container {
          .registration__privacy-list-text.label {
            font-size: 18px;
          }
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .date-time {
        h4 {
          font-size: 20px;
          margin: 10px 0;
        }
        .appt-date-cal {
          background-position: 4.5em 0.25em;
        }
        .appt-date {
          span {
            font-size: 20px;
          }
        }
      }
      .your-lessons {
        span {
          font-size: 20px;
        }
      }
      .location {
        span {
          font-size: 20px;
        }
      }
    }
  }
  .my-appointments {
    .appointment-container {
      .appointment-details {
        .date-time {
          h4 {
            font-size: 20px;
            margin: 10px 0;
          }
          .appt-date-cal {
            background-position: 4.5em 0.25em;
          }
          .appt-date {
            span {
              font-size: 20px;
            }
          }
        }
        .your-lessons {
          span {
            font-size: 20px;
          }
        }
        .location {
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}
